export class ApiException extends Error {
  errors: Record<string, any>;
  statusCode: number;

  constructor(message: string, errors = {}, statusCode = 400) {
    super(message);
    this.errors = errors;
    this.name = "ApiException";
    this.statusCode = statusCode;
  }

  static fromError(error: any): ApiException {
    const errors = error.response.data.resp.errors;
    if (errors) {
      return new ApiException(error.message, errors, error.response.status);
    }
    return new ApiException(error.message);
  }
}
