class LineItem {
  priceBeforeDiscountVatIncluded: number;
  priceAfterDiscountVatIncluded: number;
  publicName: string;
  isAdditionalProduct: boolean;
  displayName?: string;

  constructor(init?: Partial<LineItem>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        priceAfterDiscountVatIncluded: rawData.price_after_discounts_vat_included,
        priceBeforeDiscountVatIncluded: rawData.price_before_discounts_vat_included,
        publicName: rawData.public_name,
        isAdditionalProduct: rawData.is_additional_product,
        displayName: rawData.display_name
      });
    }
  }
}

export default class PriceBreakdown {
  lineItems: Array<LineItem>;
  petName: string;
  totalPrice: number;
  promoRewards: Array<string>;

  constructor(init?: Partial<PriceBreakdown>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        lineItems: rawData.line_items.map((item: any) => new LineItem({}, item)),
        subscription: {
          subscriptionId: rawData.subscription.subscription_id,
          orderDays: rawData.subscription.order_days,
          pet: {
            petId: rawData.subscription.pet.pet_id,
            name: rawData.subscription.pet.name,
            dietType: rawData.subscription.pet.algorithm_type
          }
        },
        totalPrice: rawData.total_price_vat_included,
        promoRewards: this.getPromoRewards(rawData),
        discount: rawData.total_discount,
        totalPriceBeforeDiscount: rawData.total_price_before_discounts_vat_included
      });
    }
  }

  private getPromoRewards(subscription: any): Array<string> {
    return (subscription.promo_rewards || [])
      .filter((item: any) => item.description)
      .map((item: any) => item.description);
  }
}
