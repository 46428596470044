
import PromoInfo from "@/components/PromoInfo.vue";
import Vue, { PropType } from "vue";
import PriceBreakdown from "@/models/price-breakdown.model";
import { capitalize } from "@/utils/capitalize.function";
import { determineDietType } from "@/utils/determineDietType.function";
import { toFixed } from "@/utils/currency";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import DeletePetModal from "@/components/BrandModal/DeletePetModal.vue";

Vue.filter("capitalize", capitalize);
Vue.filter("determineDietType", determineDietType);

export default Vue.extend<any, any, any, any>({
  name: "PriceSummaryTable",
  components: { LoadingSpinner, PromoInfo, DeletePetModal },
  props: {
    customerId: {
      type: Number
    },
    breakdowns: {
      type: Array as PropType<Array<PriceBreakdown>>
    },
    total: {
      type: Number
    },
    loading: {
      type: Boolean
    },
    showLinkToPlan: {
      type: Boolean,
      default: true
    },
    totalBeforeDiscount: {
      type: Number
    },
    totalPricePerDay: {
      type: Number
    },
    totalDiscount: {
      type: Number
    }
  },
  data() {
    return {
      deleteDialog: false
    };
  },
  methods: {
    async viewNutritionalPlan(petId: number) {
      await this.$router.push({
        name: this.routeNames.NUTRITIONAL_PLAN,
        params: { pet_id: petId.toString() }
      });
    },
    async deletePet(petId: number) {
      this.$emit("deletePet", petId);
    }
  },
  filters: {
    toFixed: toFixed
  }
});
