import { TotalPriceBreakdownResponse } from "@/api/api.dto";
import axios from "axios";
import round from "@/utils/round.function";
import { BlendDataDTO, BlendProductDTO, FeedingGuideDTO } from "@/api/v2/feeding-plan";

export class OrderInfoNutritionalPlanDTO {
  constructor(
    public blendData: BlendDataDTO,
    public blendProduct: BlendProductDTO,
    public feedingGuide: FeedingGuideDTO,
    public originalTargetKcal: number,
    public totalDailyKcalRequirement: number,
    public targetDailyDryKcal: number,
    public targetKcalPercent: number,
    public orderDays: number,
    public kcalRange: any
  ) {}

  public static buildFromJson(json: any) {
    return new OrderInfoNutritionalPlanDTO(
      BlendDataDTO.buildFromJson(json.blend_data),
      BlendProductDTO.buildFromJson(json.blend_product),
      FeedingGuideDTO.buildFromJson(json.feeding_guide),
      json.original_target_kcal,
      json.total_daily_kcal_requirement,
      json.target_daily_dry_kcal,
      json.target_kcal_percent,
      json.order_days,
      {
        min: Math.ceil(json.original_target_kcal * 0.5),
        max: Math.ceil(json.original_target_kcal * 1.5)
      }
    );
  }

  public fullDailyKcalRequirement(): number {
    if (this.targetDailyDryKcal && this.targetKcalPercent) {
      const multiplier = 100 / this.targetKcalPercent;
      return round(this.targetDailyDryKcal * multiplier, 0);
    }
    return 0;
  }
}

export class OrderPetDto {
  constructor(
    public priceBeforeDiscount: number | string,
    public price: number | string,
    public name: string,
    public age: any,
    public weight: number,
    public breed: any,
    public lineItems: Array<any>,
    public healthIssues: Array<string>,
    public feedingPlan: any
  ) {
    if (feedingPlan) {
      this.feedingPlan = OrderInfoNutritionalPlanDTO.buildFromJson(feedingPlan);
    }
  }

  get hasFortiflora(): boolean {
    if (this.lineItems.length === 0) {
      return false;
    }
    return this.lineItems.some((item: any) => item.is_additional_product);
  }

  get fortiflora(): any {
    if (this.hasFortiflora)
      return this.lineItems.filter((item: any) => item.is_additional_product)[0];
    return null;
  }

  get dryFood(): any {
    return this.lineItems.filter((item: any) => !item.is_additional_product)[0];
  }

  public ageDisplay(): string {
    if (!this.age) {
      return "n/a";
    }
    let ageText = "";
    if (this.age.years === 0 && this.age.months === 0) {
      return this.age.days === 1 ? "1 day" : `${this.age.days} days`;
    }
    if (this.age.years === 1) {
      ageText += "1 year";
    }
    if (this.age.years > 1) {
      ageText += `${this.age.years} years`;
    }
    if (this.age.months === 1) {
      ageText += ageText ? ", 1 month" : "1 month";
    }
    if (this.age.months > 1) {
      ageText += ageText ? `, ${this.age.months} months` : `${this.age.months} months`;
    }
    return ageText;
  }
}

export class OrderInfoDto {
  constructor(
    public totalPriceBeforeDiscount: string,
    public totalPrice: string,
    public pricePerDay: string,
    public orderDays: number,
    public discount: string,
    public delivery: any,
    public pets: Array<OrderPetDto>
  ) {}
}

class PriceBreakdownService {
  getTotalPriceBreakdownForCustomer(customerId: number): Promise<TotalPriceBreakdownResponse> {
    return axios
      .get(`/api/vets/v1/customer/${customerId}/subscriptions/total_price/`)
      .then(result => result.data.resp);
  }
  getTotalPriceBreakdownForPet(petId: number): Promise<TotalPriceBreakdownResponse> {
    return axios
      .get(`/api/vets/v1/pet/${petId}/subscription/total_price/`)
      .then(result => result.data.resp);
  }
  async getOrderInfoForCustomer(customerId: number) {
    const data = await axios
      .get(`/api/vets/prototype/customer/${customerId}/order-info`)
      .then(response => response.data.resp);
    return new OrderInfoDto(
      data.total_price_before_discounts,
      data.total_price,
      data.price_per_day,
      data.order_days,
      data.discount,
      {
        price: data.delivery.price,
        priceBeforeDiscount: data.delivery.price_before_discount
      },
      data.pets.map(
        (obj: any) =>
          new OrderPetDto(
            obj.price_before_discount,
            obj.price,
            obj.pet_info.name,
            obj.pet_info.age,
            obj.pet_info.weight,
            obj.pet_info.breed,
            obj.line_items,
            obj.pet_info.health_issues,
            obj.pet_info.feeding_plan
          )
      )
    );
  }
}

const priceBreakdownService = new PriceBreakdownService();
export default priceBreakdownService;
