
import Vue from "vue";

export default Vue.extend({
  name: "PromoInfo",
  props: {
    promos: {
      type: Array
    }
  }
});
