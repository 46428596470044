export const determineDietType = (string: string): string => {
  switch (string) {
    case "vet_maintenance":
      return "Maintenance ";
    case "vet_therapeutic":
      return "Therapeutic ";
    default:
      return "";
  }
};
