import axios from "axios";
import pluralize from "@/utils/pluralize.function";
import { ApiException } from "@/api/v2/api-exception";

export class BlendProductDTO {
  constructor(
    public orderDays: number,
    public weightPerDaysGrams: number,
    public weightPerOrderGrams: number
  ) {}

  public static buildFromJson(json: any) {
    return new BlendProductDTO(
      json.order_days,
      json.weight_per_days_grams,
      json.weight_per_order_grams
    );
  }
}

export class FeedingGuideDTO {
  constructor(
    public feedsPerDay: number,
    public scoopPosition: string,
    public scoopsPerFeed: number
  ) {}

  public static buildFromJson(json: any) {
    return new FeedingGuideDTO(json.feeds_per_day, json.scoop_position, json.scoops_per_feed);
  }

  public scoopsFeedsPerDayDisplay(): string {
    return pluralize(this.feedsPerDay, "time");
  }

  public scoopsPerFeedDisplay(): string {
    return pluralize(this.scoopsPerFeed, "scoop");
  }
}

export class BenefitsDTO {
  constructor(public name: string, public description: string) {}
}

export class CompositionDTO {
  public details: string;
  public asterisks: string[];

  constructor(rawString: string) {
    const splited = rawString.split("\n");
    this.details = splited[0];
    this.asterisks = splited.slice(1);
  }
}

export class BlendDataDTO {
  constructor(
    public additives: Record<string, any>,
    public analyticalConstituents: string,
    public claims: Array<BenefitsDTO>,
    public composition: CompositionDTO,
    public identifier: string
  ) {}

  public static buildFromJson(json: any) {
    return new BlendDataDTO(
      json.additives,
      json.analytical_constituents,
      json.claims,
      new CompositionDTO(json.composition),
      json.identifier
    );
  }
}

export class PriceBreakdownDTO {
  constructor(
    public pricePerDay: number,
    public totalPriceBeforeDiscount: number,
    public totalPriceVatIncluded: number
  ) {}

  public static buildFromJson(json: any) {
    return new PriceBreakdownDTO(
      json.price_per_day,
      json.total_price_before_discount_vat_included,
      json.total_price_vat_included
    );
  }
}

export class NutritionalPlanDTO {
  constructor(
    public blendData: BlendDataDTO,
    public blendProduct: BlendProductDTO,
    public feedingGuide: FeedingGuideDTO,
    public originalTargetKcal: number,
    public totalDailyKcalRequirement: number,
    public targetDailyDryKcal: number,
    public targetKcalPercent: number,
    public orderDays: number,
    public priceBreakdown: PriceBreakdownDTO,
    public kcalRange: any
  ) {}

  public static buildFromJson(json: any) {
    return new NutritionalPlanDTO(
      BlendDataDTO.buildFromJson(json.blend_data),
      BlendProductDTO.buildFromJson(json.blend_product),
      FeedingGuideDTO.buildFromJson(json.feeding_guide),
      json.original_target_kcal,
      json.total_daily_kcal_requirement,
      json.target_daily_dry_kcal,
      json.target_kcal_percent,
      json.order_days,
      PriceBreakdownDTO.buildFromJson(json.price_breakdown),
      {
        min: Math.ceil(json.original_target_kcal * 0.5),
        max: Math.ceil(json.original_target_kcal * 1.5)
      }
    );
  }
}

export class FeedingPlanAPI {
  static readonly baseUrl = "/api/vets/v1/pet";

  static async getNutritionalPlan(petId: number): Promise<NutritionalPlanDTO> {
    try {
      const axiosResponse = await axios.get(`${this.baseUrl}/${petId}/nutritional-plan`);
      return NutritionalPlanDTO.buildFromJson(axiosResponse.data.resp);
    } catch (error) {
      throw ApiException.fromError(error);
    }
  }

  static async getPriceBreakdownForPet(petId: number): Promise<PriceBreakdownDTO> {
    try {
      const axiosResponse = await axios.get(`/api/vets/v1/pet/${petId}/price-breakdown/`);
      return PriceBreakdownDTO.buildFromJson(axiosResponse.data.resp);
    } catch (error) {
      throw ApiException.fromError(error);
    }
  }
}
